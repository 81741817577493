/*

UI for prompting for permission for mobile device gyroscope (deviceorientation)

We use the orientation events from the browser to offset the view angle into the
hologram, giving it a 3D effect when you tilt your device

*/

import { Switch, Transition, TransitionChild } from "@headlessui/react"
import {
	ChevronDownIcon,
	ChevronUpIcon,
	DocumentDuplicateIcon as DuplicateIcon,
	MagnifyingGlassIcon as SearchIcon,
} from "@heroicons/react/24/outline"
import { classNames } from "lib/classNames"
import { AppState, useStore } from "hooks/useStore"
import { IS_LOCAL_DEV } from "lib/environment"
import { Fragment, useCallback, useEffect, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { CogIcon } from "@heroicons/react/24/solid"

// Dev note: On the console, use
// `localStorage.removeItem("gyroWarningDismissed")` to remove the flag that
// gets set when the user clicks "Dismissed"
const LOCAL_STORAGE_KEY = "gyroWarningDismissed"

// Set POLYFILL to true to test gyro permissions flow in a desktop browser.
const POLYFILL = true

// If POLYFILL is true, stub in an implementation of the
// DeviceOrientationEvent.requestPermission function which exists on iOS.
if (POLYFILL && typeof window !== "undefined") {
	if (!window.DeviceOrientationEvent) (window as any).DeviceOrientationEvent = {}
	if (!(window.DeviceOrientationEvent as any).requestPermission) {
		;(window.DeviceOrientationEvent as any).requestPermission = async function () {
			if (window.confirm("Allow gyro?")) return "granted"
			return "denied"
		}
	}
}

export const browserNeedsGyroPermission =
	POLYFILL ||
	(typeof window !== "undefined" &&
		window.DeviceOrientationEvent &&
		typeof (window.DeviceOrientationEvent as any).requestPermission === "function")

/** The explanation for how to go into ios settings and remove website data */
function MobileSafariWebsiteDataExplanation() {
	const [copiedToClipboard, setCopiedToClipboard] = useState(false)

	const websiteOrigin = window.location.origin

	return (
		<div className="text-[0.74em] text-gray-100">
			<ul className="ml-2 mt-2 list-decimal text-[10.5px] text-xs">
				<li className="mb-2">
					<div>
						<strong>Go to</strong>
					</div>
					<div>
						<CogIcon className="mr-1 inline w-3 rounded bg-gray-500" />
						Settings &gt; Safari &gt; Advanced &gt; Website data
					</div>
				</li>
				<li className="mb-2">
					<div>
						<strong>Search for</strong>
					</div>
					<CopyToClipboard
						text={websiteOrigin}
						onCopy={() => {
							setCopiedToClipboard(true)
						}}>
						<div className="m-1 rounded-full border border-solid border-slate-400 p-1">
							<SearchIcon className="inline w-4" /> {websiteOrigin}
							<span className="float-right">
								{copiedToClipboard ? "Copied!" : "Copy"} <DuplicateIcon className="inline w-4" />
							</span>
						</div>
					</CopyToClipboard>
				</li>
				<li className="mb-2">
					<strong>Swipe left on that entry to delete, then come back here!</strong>
				</li>
			</ul>
		</div>
	)
}

/** Shows a prompt explaining to the user why we need permissions for the gyroscope, and shows a button
 * they can click that will have the browser request access to it. (As of 2022, iOS devices are the only
 * devices that require permission.) */
export function SmallGyroUI() {
	/** The number of Hologram components being rendered on the page right now.
	 * We only show this permissions UI if we're rendering holograms. */
	const numHolograms = useStore((state: AppState) => state.numHolograms)
	const enableGyroPrompt = useStore((state: AppState) => state.enableGyroPrompt)

	/** Whether the switch is enabled. */
	const [enabled, _setEnabled] = useState(false)

	/** True when the OS denies permission to read the gyro. */
	const [osPermissionDenied, setOSPermissionDenied] = useState(false)

	/** True once this component has mounted. */
	const [ready, setReady] = useState(false)

	/** True if we've received any deviceorientation events from the browser. */
	const [didReceiveOrientationEvent, setDidReceiveOrientationEvent] = useState(false)

	const [isSmall, setIsSmall] = useState(false)

	/** If the user has dismissed the second warning popup. */
	const [warningHasBeenDismissed, _setWarningHasBeenDismissed] = useState(false)
	const setWarningHasBeenDismissed = useCallback((val: boolean) => {
		try {
			if (val) localStorage.setItem(LOCAL_STORAGE_KEY, "true")
			else localStorage.removeItem(LOCAL_STORAGE_KEY)
		} catch (e) {
			console.error(e) // Browsers can deny access to localStorage by throwing an exception.
		}
		_setWarningHasBeenDismissed(val)
	}, [])

	/** If true, we are keeping the UI on screen for a moment to indicate that permission was successfully granted. */
	const [showTemporarilyAfterGranted, setShowTemporarilyAfterGranted] = useState(false)

	const onOrientation = useCallback(
		(e: DeviceOrientationEvent) => {
			if (!didReceiveOrientationEvent) setDidReceiveOrientationEvent(true)
		},
		[didReceiveOrientationEvent],
	)

	const askOSForPermission = useCallback(async () => {
		_setEnabled(false)

		let response = ""
		try {
			response = await (window.DeviceOrientationEvent as any).requestPermission()
		} catch (e: any) {
			console.error(e)
		}

		setOSPermissionDenied(response === "denied")
		if (response === "granted") {
			_setEnabled(true)

			// Temporarily keep the UI on screen after we were granted
			// permission, so that the user can see that the action was
			// successful.
			if (!didReceiveOrientationEvent) {
				setShowTemporarilyAfterGranted(true)
				setTimeout(() => {
					setShowTemporarilyAfterGranted(false)
				}, 2500)
			}

			setDidReceiveOrientationEvent(true)
		}
	}, [])

	const [showExplanation, setShowExplanation] = useState(false)

	const enableSwitchAndRequestPermission = useCallback(async () => {
		_setEnabled(true)
		await askOSForPermission()
	}, [askOSForPermission])

	// Check if the user has already dismissed the gyro UI permanently.
	// Also listen for deviceorientation events from the browser--if we ever get
	// them, we know that we have successfully been granted permission.
	useEffect(() => {
		// We need useEffect to lookup the localStorage value for if the user has
		// chosen to ignore any warnings about gyroscope permissions, or the
		// serverside and client side HTML renders will not match, and next will
		// complain.
		let dismissed = false
		try {
			dismissed = !!localStorage.getItem(LOCAL_STORAGE_KEY)
		} catch (e) {
			console.error(e)
		}
		_setWarningHasBeenDismissed(dismissed)
		if (dismissed) return // we don't need any other logic if the user has dismissed this UI permanently.

		// Don't ever show the UI on non-http hosts. iOS will automatically deny requests from non-http websites.
		const isHTTPS = window.location.protocol === "https:"
		if (!isHTTPS && !POLYFILL) {
			// Remind developers using localhost that accelerometer UI will not show in HTTPs. (POLYFILL overrides this.)
			if (IS_LOCAL_DEV)
				console.log("ACCELEROMETER: Disabling accelerometer support because we're not on HTTPs.")
			return
		}

		window.addEventListener("deviceorientation", onOrientation)

		// Give the browser some time to possibly deliver us `deviceorientation` events. If we get those,
		// we know we don't need any modal.
		const timeout = setTimeout(() => {
			setReady(true)
		}, 3000)

		return () => {
			clearTimeout(timeout)
			window.removeEventListener("deviceorientation", onOrientation)
		}
	}, [onOrientation])

	const chevronProps = {
		onClick: (e: any) => {
			setShowExplanation(!showExplanation)
			e.preventDefault()
		},
		className: "cursor-pointer min-w-10 w-10 text-gray-400",
	}

	const showGyroUI =
		enableGyroPrompt &&
		numHolograms > 0 &&
		ready &&
		(showTemporarilyAfterGranted ||
			(!didReceiveOrientationEvent && browserNeedsGyroPermission && !warningHasBeenDismissed))

	return (
		<Transition show={!!showGyroUI} as={Fragment}>
			<TransitionChild
				as={Fragment}
				enter="ease-out duration-300"
				enterFrom="opacity-0 translate-y-4"
				enterTo="opacity-100 translate-y-0"
				leave="ease-in duration-200"
				leaveFrom="opacity-100 translate-y-0"
				leaveTo="opacity-0 translate-y-4">
				<div
					className={classNames(
						`fixed bottom-[60px] left-0 z-[1] m-3 mb-5 flex cursor-pointer items-center space-x-2 bg-black/40 p-2 text-sm backdrop-blur-lg transition-all`,
						isSmall ? "rounded-full" : "right-0 rounded-xl",
					)}
					onClick={(e) => {
						if (isSmall) {
							setIsSmall(false)
							e.preventDefault()
						}
					}}>
					{/* A small phone-with-a-face icon */}
					{osPermissionDenied && (
						<img width="30" height="32" src="/looky-dead.svg" alt="Small phone with sad face" />
					)}
					{!osPermissionDenied && (
						<img width="24" height="26" src="/looky.svg" alt="Small phone with happy face" />
					)}

					{!isSmall && (
						<>
							<div
								className="grow"
								onClick={(e) => {
									if (osPermissionDenied && !showExplanation) {
										setShowExplanation(!showExplanation)
										e.preventDefault()
									} else if (!osPermissionDenied) {
										enableSwitchAndRequestPermission()
										e.preventDefault()
									}
								}}>
								<div className="whitespace-nowrap font-bold">
									{didReceiveOrientationEvent && <p>Accelerometer enabled! </p>}
									{!didReceiveOrientationEvent && <p>Tilt device to view in 3D </p>}
									{osPermissionDenied && <span className="ml-1 text-xs font-normal opacity-50">Denied</span>}
								</div>
								{!didReceiveOrientationEvent && (
									<>
										<div className="text-[10.5px] text-xs text-gray-300">
											{osPermissionDenied && <>Learn how to enable iOS accelerometer.</>}
											{!osPermissionDenied && <>Enable iOS accelerometer support.</>}{" "}
											<span
												style={{ textDecoration: "underline" }}
												onClick={(e) => {
													setWarningHasBeenDismissed(true)
													e.preventDefault()
												}}>
												Dismiss
											</span>
										</div>
									</>
								)}

								{osPermissionDenied && showExplanation && <MobileSafariWebsiteDataExplanation />}
							</div>
							{!osPermissionDenied && (
								<Switch
									checked={enabled}
									onChange={enableSwitchAndRequestPermission}
									className={`${
										enabled ? "holo5" : "bg-gray-200"
									} relative inline-flex h-8 w-14 min-w-14 items-center rounded-full`}>
									<span className="sr-only">Enable accelerometer</span>
									<span
										className={`${
											enabled ? "translate-x-6" : "translate-x-1"
										} inline-block h-6 w-6 transform rounded-full bg-white transition duration-200 ease-in-out`}
									/>
								</Switch>
							)}
							{osPermissionDenied && showExplanation && <ChevronUpIcon {...chevronProps} />}
							{osPermissionDenied && !showExplanation && <ChevronDownIcon {...chevronProps} />}
						</>
					)}
				</div>
			</TransitionChild>
		</Transition>
	)
}
