import { trpc } from "@/lib/trpc/trpc"
import { getHologramPath } from "@/lib/utils"
import {
	ArrowDownIcon,
	ArrowTurnDownLeftIcon,
	ArrowUpIcon,
	MagnifyingGlassIcon,
	XMarkIcon,
} from "@heroicons/react/24/solid"
import { AnimatePresence, motion } from "framer-motion"
import Link from "next/link"
import { useRouter } from "next/router"
import { useCallback, useEffect, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"
import SpinnerLoader from "../SpinnerLoader"
import FilterButton from "./FilterButton"
import SearchItem from "./SearchItem"
import { useBlocksUserStore } from "@/hooks/useStore"
import { useBreakpoint } from "@/hooks/useBreakpoints"

type FilterType = "Trending" | "Recent Uploads" | "Staff Picks" | "Library"
let filters: FilterType[] = ["Trending", "Recent Uploads", "Staff Picks", "Library"]

const MAX_ITEMS = 4

interface SearchBarProps {
	autofocus?: boolean
	className?: string
	onClear?: () => void
}

export default function SearchBar(props: SearchBarProps) {
	const { autofocus, onClear } = props
	const router = useRouter()
	const containerRef = useRef<HTMLDivElement>(null)
	const inputRef = useRef<HTMLInputElement>(null)
	const [focus, setFocus] = useState(false)
	const [activeIndex, setActiveIndex] = useState(-1)
	const [searchValue, setSearchValue] = useState("")
	const [activeFilter, setActiveFilter] = useState<FilterType | undefined>("Trending")
	const dbUser = useBlocksUserStore((state) => state.dbUser)
	const isDesktop = useBreakpoint("md")

	const { data, isFetching } = trpc.hologram.search.useInfiniteQuery(
		{
			term: searchValue,
			limit: 10,
			filter: activeFilter,
		},
		{
			getNextPageParam: (lastPage) => lastPage.nextCursor,
		},
	)

	const searchData = data?.pages?.flatMap((page) => page.holograms)
	const showSpinner = !searchData && isFetching
	const showResults = !!searchData && searchData.length > 0
	const showNoResults = !!searchData && searchData.length === 0

	useEffect(() => {
		if (!focus) {
			setActiveIndex(-1)
		}
	}, [focus])

	const handleNavigation = useCallback(
		(direction: "up" | "down") => {
			if (!searchData || !focus) return
			const newIndex = activeIndex + (direction === "up" ? -1 : 1)
			if (newIndex > MAX_ITEMS - 1) {
				setActiveIndex(0)
			} else if (newIndex < 0) {
				setActiveIndex(MAX_ITEMS)
			} else {
				setActiveIndex(newIndex)
			}
		},
		[activeIndex, setActiveIndex, searchData, focus],
	)

	const handleEnter = useCallback(() => {
		if (!focus) return
		if (activeIndex === -1) {
			router.push(`/search?q=${encodeURIComponent(searchValue)}`)
		}
		const hologram = searchData?.at(activeIndex)
		if (hologram) {
			const path = getHologramPath(hologram)
			router.push(path)
		}
		setFocus(false)
	}, [activeIndex, searchData, focus, searchValue])

	useEffect(() => {
		const onMouseDown = (e: MouseEvent) => {
			const target = e.target as HTMLElement
			if (target.id === "search-btn") return
			const isClickInside = containerRef.current?.contains(target)
			if (!isClickInside) {
				setFocus(false)
				if (!isDesktop) {
					onClear?.()
				}
			}
		}

		const onKeyUp = (e: KeyboardEvent) => {
			switch (e.key) {
				case "ArrowUp": {
					handleNavigation("up")
					break
				}
				case "ArrowDown": {
					handleNavigation("down")
					break
				}
				case "Enter": {
					handleEnter()
					break
				}
				case "Escape": {
					inputRef.current?.blur()
					setFocus(false)
					break
				}
				case "/": {
					if (document.activeElement === inputRef.current) return
					setFocus(true)
					inputRef.current?.focus()
					break
				}
			}
		}

		const onRouteChangeState = () => {
			setFocus(false)
		}

		document.addEventListener("keyup", onKeyUp)
		document.addEventListener("mousedown", onMouseDown)
		router.events.on("routeChangeStart", onRouteChangeState)
		return () => {
			document.removeEventListener("keyup", onKeyUp)
			document.removeEventListener("mousedown", onMouseDown)
			router.events.off("routeChangeStart", onRouteChangeState)
		}
	}, [handleNavigation, handleEnter, isDesktop])

	useEffect(() => {
		if (autofocus) {
			inputRef.current?.focus()
		}
	}, [inputRef])

	return (
		<motion.div
			ref={containerRef}
			initial={{ width: "240px", outlineColor: "rgba(255, 255, 255, 0.5)" }}
			animate={{
				width: focus ? "420px" : "240px",
				backgroundColor: focus ? "#6A68A880" : "transparent",
				outlineColor: focus ? "rgba(0, 0, 0, 0.0)" : "rgba(255, 255, 255, 0.5)",
				transition: { duration: 0.2, ease: "easeInOut" },
				height: "fit-content",
			}}
			className={twMerge(
				"relative flex-col rounded-md outline outline-1",
				focus && "rounded-b-none rounded-t-md",
			)}>
			<div
				className={twMerge(
					"flex w-full items-center gap-3 rounded-md px-4 py-2 backdrop-blur-lg",
					focus && "rounded-b-none rounded-t-md",
				)}>
				<MagnifyingGlassIcon className="m-2 h-[16px] w-[16px] flex-none fill-white" />
				<input
					ref={inputRef}
					onFocus={() => setFocus(true)}
					type="text"
					placeholder={focus ? "" : "Search"}
					value={searchValue}
					onChange={(e) => {
						!focus && setFocus(true)
						setSearchValue(e.target.value)
					}}
					className="w-full max-w-full bg-transparent text-white placeholder:text-white/50 focus:outline-none"
				/>
				<span className={twMerge("bg-gray-500/20 p-1 text-sm text-white", focus ? "hidden" : "block")}>
					/
				</span>
				<XMarkIcon
					className={twMerge(
						"h-6 w-6 cursor-pointer fill-white/50 hover:fill-white",
						focus && searchValue ? "block" : "hidden",
					)}
					onClick={() => {
						setSearchValue("")
						onClear?.()
						setFocus(false)
					}}
				/>
			</div>
			<AnimatePresence mode="wait">
				{focus && (
					<div className="absolute w-full rounded-b-md bg-[#6A68A880] backdrop-blur-lg">
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.2, ease: "easeInOut" }}
							className="flex w-full items-center justify-around gap-2 overflow-x-auto border-b border-t border-white/20 p-4 md:overflow-x-hidden">
							{filters
								.filter((f) => (!dbUser ? f !== "Library" : true))
								.map((filter) => (
									<FilterButton
										active={activeFilter === filter}
										onClick={() => {
											if (activeFilter === filter) {
												setActiveFilter(undefined)
											} else {
												setActiveFilter(filter)
											}
										}}
										key={filter}>
										{filter}
									</FilterButton>
								))}
						</motion.div>
						{showResults && (
							<motion.div className="mt-5">
								{searchData
									?.slice(0, MAX_ITEMS)
									.map((hologram, index) => (
										<SearchItem
											active={activeIndex === index}
											onHover={() => setActiveIndex(index)}
											hologram={hologram}
											key={index}
										/>
									))}
								{!!searchValue && (
									<Link
										className="block w-min whitespace-nowrap px-4 py-3 text-xs font-bold text-[#C699FC] hover:text-[#d08aff]"
										href={`/search?q=${encodeURIComponent(searchValue)}`}>
										Show more
									</Link>
								)}
							</motion.div>
						)}
						{showSpinner && <SpinnerLoader className="mx-auto my-5 h-8 w-8" />}
						{showNoResults && (
							<motion.div className="flex h-[230px] w-full items-center justify-center text-white">
								No results found
							</motion.div>
						)}
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							className="flex w-full justify-evenly gap-5 overflow-x-hidden border-t border-white/20 px-3 py-4">
							<span className="flex items-center gap-2 text-white">
								<span className="flex items-center gap-1">
									<ArrowUpIcon className="h-6 w-6 rounded-sm bg-gray-500 fill-white p-1" />
									<ArrowDownIcon className="h-6 w-6 rounded-sm bg-gray-500 fill-white p-1" />
								</span>
								Move
							</span>
							<span className="flex items-center gap-2 text-white">
								<ArrowTurnDownLeftIcon className="h-6 w-6 rounded-sm bg-gray-500 fill-white p-1" />
								Select
							</span>
							<span className="flex items-center gap-2 text-white">
								<span className="rounded-sm bg-gray-500 p-1 text-xs text-white">ESC</span> Close
							</span>
						</motion.div>
					</div>
				)}
			</AnimatePresence>
		</motion.div>
	)
}
