import { PropsWithChildren } from "react"
import { twMerge } from "tailwind-merge"
import { motion } from "framer-motion"

interface FilterButtonProps extends PropsWithChildren {
	active?: boolean
	onClick?: () => void
}
export default function FilterButton(props: FilterButtonProps) {
	const { children, active, onClick } = props

	return (
		<motion.button
			onClick={onClick}
			initial={{ opacity: 0 }}
			transition={{ opacity: { duration: "0.5", ease: "easeInOut" } }}
			animate={{
				opacity: 1,
				backgroundColor: active ? "rgba(255, 255, 255, 0.1)" : "rgba(255, 255, 255, 0)",
			}}
			className={twMerge("whitespace-nowrap rounded-sm px-[10px] py-1 text-sm text-white")}>
			{children}
		</motion.button>
	)
}
