import Queue from "bull"
import { getRedisUrl } from "../lib/redisClient"
import Redis, { type CommonRedisOptions } from "ioredis"

let redisUrl = getRedisUrl()
let clients: Record<string, Redis> = {}
let subscribers: Record<string, Redis> = {}

export const getQueue = (queueName: string) => {
	return new Queue(queueName, {
		createClient(type, redisOpts) {
			const opts: CommonRedisOptions = {
				maxRetriesPerRequest: null,
				enableReadyCheck: false,
				...redisOpts,
			}

			switch (type) {
				case "client":
					if (!clients[redisUrl]) {
						clients[redisUrl] = new Redis(redisUrl, opts)
					}
					return clients[redisUrl]
				case "subscriber":
					if (!subscribers[redisUrl]) {
						subscribers[redisUrl] = new Redis(redisUrl, opts)
					}
					return subscribers[redisUrl]
				case "bclient":
					return new Redis(redisUrl, opts)
				default:
					throw new Error("Unexpected connection type: ", type)
			}
		},
	})
}
