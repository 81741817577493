import { useBreakpoint } from "@/hooks/useBreakpoints"
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid"
import { UserProfileImage } from "components/UserProfileImage"
import CompassIcon from "components/icons/CompassIcon"
import LibraryIcon from "components/icons/LibraryIcon"
import { useBlocksUserStore } from "hooks/useStore"
import { useRouter } from "next/router"
import { useCallback, useEffect, useState } from "react"
import { twMerge } from "tailwind-merge"
import SearchBar from "../SearchBar"

export default function MobileNavFooter() {
	const [showSearch, setShowSearch] = useState(false)
	const user = useBlocksUserStore((store) => store.dbUser)
	const router = useRouter()
	const isDesktop = useBreakpoint("md")
	const [targetPath, setTargetPath] = useState<string | null>(router.pathname)
	const { username } = user ?? {}

	const footerClassName =
		" fixed bottom-0 py-2 h-[64px] flex w-full items-center justify-around z-[9999] sm:hidden pointer-events-auto transition-opacity duration-200"
	const footerItemClassName =
		"flex-grow h-full flex items-center flex-1 justify-center cursor-pointer active:scale-90 transition-transform overflow-visible"
	const bgClassName =
		"bg-purple-grad absolute z-[-1] mx-auto aspect-1 h-[90%] rounded-full opacity-0 transition-opacity ease-in-out pointer-events-none"

	const onNavClick = useCallback(
		(pathname: string) => {
			if (pathname === router.pathname) {
				return
			} else {
				router.push(pathname)
			}
		},
		[router],
	)

	useEffect(() => {
		const onRouteChangeStart = (pathname: string) => {
			setTargetPath(pathname)
			setShowSearch(false)
		}
		router.events.on("routeChangeStart", onRouteChangeStart)
		return () => {
			router.events.off("routeChangeStart", onRouteChangeStart)
		}
	}, [router])

	useEffect(() => {
		if (isDesktop) {
			setShowSearch(false)
		}
	}, [isDesktop])

	useEffect(() => {
		const overlay = document.getElementById("overlay")

		const onResize = () => {
			const height = window.visualViewport?.height
			if (!overlay) return

			if (showSearch) {
				overlay.style.height = `${height}px`
			} else {
				overlay.style.height = "100vh"
			}
		}

		if (overlay) {
			if (showSearch) {
				overlay.style.zIndex = "11"
			} else {
				overlay.style.zIndex = "9"
			}
		}

		if (!window.visualViewport) return
		window.visualViewport.addEventListener("resize", onResize)
		return () => {
			if (!window.visualViewport) return

			window.visualViewport.removeEventListener("resize", onResize)
		}
	}, [showSearch])

	return (
		<>
			<div
				id="mobile-nav-footer"
				style={{
					backgroundImage: `url(/noise.svg)`,
					backdropFilter: "blur(16px)",
				}}
				className={twMerge(footerClassName)}>
				<button
					className={twMerge(footerItemClassName)}
					onClick={() => {
						onNavClick("/discover")
					}}>
					<span
						className={twMerge(bgClassName, targetPath === "/discover" && !showSearch && "opacity-100")}
					/>
					<CompassIcon className="drop-shadow-icon h-8 w-8" />
				</button>
				<button
					className={footerItemClassName}
					onClick={() => {
						onNavClick("/library")
					}}>
					<span className={twMerge(bgClassName, targetPath === "/library" && !showSearch && "opacity-100")} />
					<LibraryIcon className="drop-shadow-icon h-8 w-8" />
				</button>
				{user && (
					<button
						className={footerItemClassName}
						onClick={() => {
							onNavClick(`/${user?.username}`)
						}}>
						<span
							className={twMerge(bgClassName, targetPath === `/${username}` && !showSearch && "opacity-100")}
						/>
						<UserProfileImage className="drop-shadow-icon h-8 w-8" user={user} />
					</button>
				)}
				<button
					id="search-btn"
					className={footerItemClassName}
					onClick={(e) => {
						setShowSearch(!showSearch)
					}}>
					<span className={twMerge(bgClassName, !!showSearch && "opacity-100")} />
					<MagnifyingGlassIcon className="drop-shadow-icon pointer-events-none h-8 w-8 fill-white" />
				</button>
			</div>
			{showSearch && (
				<dialog
					className={
						"pointer-events-auto fixed top-4 z-20 flex w-full justify-center bg-transparent px-4 md:hidden"
					}>
					<SearchBar autofocus onClear={() => setShowSearch(false)} />
				</dialog>
			)}
		</>
	)
}
