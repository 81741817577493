import Nav from "components/Navigation/index"
import useHubspotSync from "hooks/useHubspotSync"
import useOnboardingStore from "hooks/useOnboardingStore"
import { useBlocksUserStore } from "hooks/useStore"
import { PagesProgressBar } from "next-nprogress-bar"
import dynamic from "next/dynamic"
import { ReactNode, useEffect, useState } from "react"
import Analytics from "./Analytics"
import { toastErrorStyle, toastSuccessStyle } from "./toastStyles"

interface LayoutProps {
	children: ReactNode
}

export default function Layout({ children }: LayoutProps) {
	const user = useBlocksUserStore((state) => state.dbUser)
	const [openPopup, setOpenPopup] = useState(false)
	const { getResumeStep, setCurrentStep, currentStep } = useOnboardingStore()

	useEffect(() => {
		if (user) {
			Promise.resolve(getResumeStep(user)).then((step) => {
				if (step) {
					setCurrentStep(step)
				}
			})
		}
	}, [user])

	useEffect(() => {
		if (currentStep) {
			setOpenPopup(true)
		} else setOpenPopup(false)
	}, [currentStep])

	useHubspotSync()

	const Toaster = dynamic(() => import("react-hot-toast").then((mod) => mod.Toaster), { ssr: false })
	const TermsBanner = dynamic(() => import("./TermsBanner").then((mod) => mod.TermsBanner), { ssr: false })
	const HelpScoutBeacon = dynamic(() => import("./HelpscoutBeacon"), {
		ssr: false,
	})
	const BulkUploaderTray = dynamic(
		() => import("./upload/BulkUploader").then((mod) => mod.BulkUploaderTray),
		{ ssr: false },
	)
	const OnboardingPopup = dynamic(() => import("./onboarding/OnboardingPopup"), { ssr: false })

	useEffect(() => {
		const onResize = () => {
			const height = window.visualViewport?.height
			const overlay = document.getElementById("overlay")
			if (!overlay) return

			overlay.style.height = `${height}px`
		}
		if (!window.visualViewport) return
		window.visualViewport.addEventListener("resize", onResize)
		return () => {
			if (!window.visualViewport) return

			window.visualViewport.removeEventListener("resize", onResize)
		}
	}, [])

	return (
		<div id="layout" className="lkg-layout">
			<Analytics />
			<PagesProgressBar
				color="#8E3FFB"
				height="2px"
				options={{ easing: "ease-out", speed: 400, showSpinner: false }}
			/>
			<Nav />
			<Toaster
				toastOptions={{ success: toastSuccessStyle, error: toastErrorStyle }}
				position="bottom-right"
			/>
			<div className="mb-[76px] mt-[80px] md:mb-0">{children}</div>
			<div id="overlay" className="pointer-events-none fixed top-0 z-[9] h-[100dvh] w-screen"></div>
			<TermsBanner />
			<HelpScoutBeacon />
			<BulkUploaderTray />
			<OnboardingPopup
				open={openPopup}
				onClose={() => {
					setOpenPopup(false)
					// Prevent a visual bug where the content is missing while the popup fades away
					setTimeout(setCurrentStep, 500)
				}}
			/>
		</div>
	)
}
