import useOnboardingStore from "hooks/useOnboardingStore"
import { AppState, useBlocksUserStore, useStore } from "hooks/useStore"
import { useRef } from "react"
import SpinnerLoader from "./SpinnerLoader"
import { PlusIcon } from "@heroicons/react/24/solid"
import { twMerge } from "tailwind-merge"

export default function UploadButton() {
	const isLoggedIn = useBlocksUserStore((state) => state.current?.isLoggedIn)
	const user = useBlocksUserStore((state) => state.dbUser)
	const isHologramUploading = useStore((state: AppState) => state.isHologramUploading)
	const { setCurrentStep, getResumeStep } = useOnboardingStore()

	const buttonRef = useRef<HTMLButtonElement | null>(null)

	async function onUploadClick() {
		if (!isLoggedIn) {
			// Must hard redirect to any auth page
			window.location.href = "/api/auth/login?returnTo=/discover"
		} else if (user) {
			const resumeStep = await getResumeStep(user)
			if (resumeStep) {
				setCurrentStep(resumeStep)
			} else {
				setCurrentStep("select_upload_type")
			}
		}
	}

	return (
		<button
			id="upload-btn"
			ref={buttonRef}
			className={twMerge(
				"flex aspect-[1.2] h-10 w-14 items-center justify-center rounded-xl bg-foil drop-shadow-md transition-all duration-300 ease-out hover:drop-shadow-xl active:drop-shadow-md",
				"transform transition-all hover:-translate-y-[2px]",
				"bg-[position:-275px_-70px] hover:bg-[position:-280px_-75px] hover:drop-shadow-md active:bg-[position:-280px_-75px]",
			)}
			onClick={onUploadClick}>
			{isHologramUploading ? <SpinnerLoader className="h-full" /> : <PlusIcon className="h-6 fill-white" />}
		</button>
	)
}
