import { getCDNUrl } from "@/lib/cdn"
import { EmbedHologram } from "@/lib/hologramProps"
import { findSourceImage } from "@/prisma/models"
import Link from "next/link"
import { getHologramPath } from "@/lib/utils"
import { twMerge } from "tailwind-merge"
import HologramThumbnail from "../HologramThumbnail"
interface SearchItemProps {
	hologram: EmbedHologram
	onHover?: () => void
	active?: boolean
}

export default function SearchItem(props: SearchItemProps) {
	const { hologram, onHover, active } = props
	const { title } = hologram

	const path = getHologramPath(hologram)

	return (
		<Link
			onMouseOver={onHover}
			href={path}
			className={twMerge("flex h-[56px] w-full items-center gap-3 px-4 py-1", active && "bg-white/10")}>
			<HologramThumbnail hologram={hologram} width={48} containerWidth={48} />
			<span className="flex-1 truncate text-white">{title}</span>
		</Link>
	)
}
